import "@fontsource/dm-sans"
import "@fontsource/dm-sans/700.css"
import "@fontsource/dm-mono"
import "@fontsource/dm-mono/500.css"
import "@fontsource/bebas-neue"
import "@fontsource/londrina-outline"
import "@fontsource/rubik-mono-one"

export default function onRouteUpdate(){
  window.analytics && window.analytics.page();
}